<template>
  <div>
    <div  class="createTest testPaper">

      <div class="w" >
        <el-row>
          <el-col  :span="22" id="testPaper"  :style="{width:aWidth}">
        <!-- 试卷信息 -->
           <div class="title " :style="{width:aWidth}">

          <el-row  :style="{ fontFamily: testData.selectedFontStyle, fontSize:  testData.fontSize + 'px' }">
            <el-col span="4">试卷名称:{{ testData.paperName }}</el-col>
            <el-col span="4">试卷类型:<span>
             {{ testData.type === 1 ? '语文' :
                testData.type === 2 ? '数学' :
                testData.type === 3 ? '英语' :
                testData.type === 4 ? '自然科学' :'未知类型' }}
            </span></el-col>
            <!--          答题时间-->
            <el-col span="4">              答题时间:<span>{{testData.time}}</span> 分钟            </el-col>
            <!--          题目数量-->
            <el-col span="4">
              题目数量: 共
              {{
                topicNavIndex_mixin(4, sortedTopics[4].topic_content.length - 1)
              }}
              道
            </el-col>
            <!--          总分-->
            <el-col span="4">总分: {{ totalScore }} 分</el-col>
            <!--          及格分数-->
            <el-col span="4">
              及格分数:
              <span>{{testData.passMark}}</span>
              分
            </el-col>


          </el-row>

        </div>
        <div id="anser"  v-if="this.answer==1||this.answer==3" class="test-content  " :style="{ fontFamily: testData.selectedFontStyle,
         fontSize:  testData.fontSize + 'px' ,width:aWidth}">
          <div v-if="this.answer==3" class="title" >
            <div style="text-align: center; border: 1px dashed #000; padding: 10px;">答题卡</div>
            <div style="display: flex; justify-content: space-between; margin-bottom: 10px;">
              <div style="width: calc(20% - 5px);">考号：________</div>
              <div style="width: calc(20% - 5px);">姓名：________</div>
              <div style="width: calc(20% - 5px);">学号：________</div>
              <div style="width: calc(20% - 5px);">班级：________</div>
              <div style="width: calc(20% - 5px);">座位号：________</div>
            </div>
            <div style="display: flex; justify-content: space-between; ">
              <div style="width: 50%;">
                <div style="text-align: center; border: 1px solid #000; padding: 10px;">注意事项</div>
                <div style="border: 1px solid #000; padding: 10px;height: 190px">
                  <p>1、答题前请将姓名、班级、考场、座号和准考证号填写清楚,</p>
                  <p>2、客观题答题，必须使用2B铝笔填涂，修改时用橡皮擦干净。</p>
                  <p>3、主观题必须使用黑色签字笔书写。</p>
                  <p>4、必须在题号对应的答题区域内作答，超出答题区域书写无效。</p>
                  <p>5、保持答卷清洁完整。</p>
                </div>
                <div style="border: 1px solid #000; padding: 10px; height: 40px">
                  <span>正确填涂：</span>
                  <div style="width: 40px; height: 15px; background-color: #000; display: inline-block; margin-left: 20px; margin-right: 20px;"></div>
                  <span>缺考标记：</span>
                  <div style="width: 40px; height: 15px; border: 1px solid #000; display: inline-block; margin-left: 20px;"></div>
                </div>
              </div>
              <div style="width: 50%;">
                <div style="text-align: center; border: 1px solid #000; padding: 10px;">准考证号</div>
                <div style="display: flex; justify-content: space-between; flex-wrap: wrap;">
                  <!-- 输入 -->
                  <div v-for="(index, i) in 10" :key="i" style="width: 10%; border: 1px solid #000; padding: 15px;"></div>
                  <!-- 输出 -->
                  <div v-for="(index, i) in 10" :key="i" style="text-align: center;width: 10%; border: 1px solid #000; padding: 4px; display: inline-block;height: 200px;">
                    <div v-for="num in 10" :key="num" :style="{ width: '30px', height: '14px', border: '1px solid #000', marginTop: num === 0 ? '-10px' : '5px' }">
                      {{ num-1 }}
                    </div>
                  </div>
                </div>
              </div>
            </div>


          </div>
          <!--    <ckeditor v-model="editorData" :config="editorConfig" :editor-url="editorUrl"></ckeditor>-->
          <div class="topics  " :style="{width: aWidth}" >
            <!-- 所有题目 -->
            <div
                class="topic"
                v-for="(s_topics, index) in sortedTopics"
                :key="index"
            >
              <!-- 按类型分类好的题目 -->
              <div class="topicsType" v-if="s_topics.topic_content.length != 0">
                <!--              大题号与统一设置分数按钮-->
                <div class="bigQuestionName">
                  <!--                计算大题号-->
                  <h4>{{ bigQuestionName_mixin(s_topics.type, index) }}</h4>
                  <!-- 题目类型名称 -->
                </div>

                <!--题目类型判断-->
                <div
                    v-for="(t, tIndex) in s_topics.topic_content"
                    :key="tIndex">
                  <div class="topic-content">

                    <!-- 单项选择题 -->
                    <span class="userAnswer" v-if="s_topics.type == 0">
                      {{ topicNavIndex_mixin(s_topics.type, tIndex) }}、  <span   v-for="(item, index) in t.choice" >【{{ String.fromCharCode(65 + index) }}】</span>
                    </span>

                    <!-- 多项选择题 -->
                    <div class="userAnswer" v-if="s_topics.type == 1">
                      {{ topicNavIndex_mixin(s_topics.type, tIndex) }}、  <span   v-for="(item, index) in t.choice" >【{{ String.fromCharCode(65 + index) }}】</span>
                    </div>

                    <!-- 判断题 -->
                    <div class="userAnswer" v-if="s_topics.type == 2">

                      <img src="../assets/yes.png" alt="" width="20" height="20">
                      <img src="../assets/no.png" alt="" width="20" height="20">

                      {{ topicNavIndex_mixin(s_topics.type, tIndex) }}、
                      <span class="TrueOrFalse">正确 &nbsp;&nbsp;错误
                      </span>
                    </div>

                    <!-- 填空题 -->
                    <div class="userAnswer" v-if="s_topics.type == 3">

                      <img src="../assets/yes.png" alt="" width="20" height="20">
                      <img src="../assets/no.png" alt="" width="20" height="20">
                      <img src="../assets/yesorno.png" alt="" width="20" height="20">
                      {{ topicNavIndex_mixin(s_topics.type, tIndex) }}、
                      <span class="fillInBlank">
                        <span
                            v-for="(q, index) in fillSymbolStr(t.question)"
                            :key="index"
                        >
                          <span>_________</span>   &nbsp;&nbsp;&nbsp;
                        </span>
                      </span>
                    </div>

                    <!-- 简答题 -->
                    <div class="userAnswer" v-if="s_topics.type == 4">
                      <div class="text">
                        <img src="../assets/yes.png" alt="" width="20" height="20">
                        <img src="../assets/no.png" alt="" width="20" height="20">
                        <img src="../assets/yesorno.png" alt="" width="20" height="20">
                        {{ topicNavIndex_mixin(s_topics.type, tIndex) }}、
                        <div style="height: 100px">

                        </div>
                        <!-- {{t.correctAnswer}} -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div style="clear: both"></div>
        </div>
        <!--编辑试卷主体-->
        <div v-if="this.answer==1||this.answer==2"  class="test-content" :style="{width:aWidth}">
          <!--    <ckeditor v-model="editorData" :config="editorConfig" :editor-url="editorUrl"></ckeditor>-->
          <div class="topics" :style="{ width:aWidth,fontFamily: testData.selectedFontStyle, fontSize:  testData.fontSize + 'px' }">
            <!-- 所有题目 -->
            <div
                class="topic"
                v-for="(s_topics, index) in sortedTopics"
                :key="index"
            >
              <!-- 按类型分类好的题目 -->
              <div class="topicsType" v-if="s_topics.topic_content.length != 0">
                <!--              大题号与统一设置分数按钮-->
                <div class="bigQuestionName">
                  <!--                计算大题号-->
                  <h4>{{ bigQuestionName_mixin(s_topics.type, index) }}</h4>
                  <!-- 题目类型名称 -->
                </div>

                <!--题目类型判断-->
                <div
                    v-for="(t, tIndex) in s_topics.topic_content"
                    :key="tIndex">
                  <div class="topic-content">
                    <!-- 题目 -->
                    <span class="question" style="display: flex;" >
                      <span :style="{ fontFamily: testData.selectedFontStyle, fontSize:  testData.fontSize + 'px' }" v-html='`${topicNavIndex_mixin_content(s_topics.type, tIndex,t.question)}`'></span>
                    </span>

                    <!-- 单项选择题 -->
                    <div class="userAnswer" v-if="s_topics.type == 0">
                      <div class="radios" v-for="(item, index) in t.choice"
                           :key="index"
                           :label="item">
                        <span class="topicNavIndex">{{ String.fromCharCode(65 + index) }}、</span>
                          <span
                              v-if="
                            editInedx.type == 0 && editInedx.index == tIndex
                          "
                          >
                        </span>
                          <span :style="{ fontFamily: testData.selectedFontStyle, fontSize:  testData.fontSize + 'px' }" v-else>{{ item }}</span>
                      </div>
                    </div>

                    <!-- 多项选择题 -->
                    <div class="userAnswer" v-if="s_topics.type == 1">
                      <div class="checkbox">
                          <div
                              :label="item"
                              v-for="(item, index) in t.choice"
                              :key="index"
                          >
                          <span class="topicNavIndex">{{ String.fromCharCode(65 + index) }}、</span >
                            <span
                                v-if="editInedx.type == 1 && editInedx.index == tIndex"
                            >
                            <el-input
                                v-model="t.choice[index]"
                                type="textarea"
                                autosize
                            ></el-input>
                          </span>
                            <span :style="{ fontFamily: testData.selectedFontStyle, fontSize:  testData.fontSize + 'px' }" v-else>{{ item }}</span>
                          </div>
                      </div>
                    </div>

                    <!-- 判断题 -->
                    <div class="userAnswer" v-if="s_topics.type == 2">
                      <div class="TrueOrFalse">
                        <span  label="false" >正确</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <span  label="false" >错误</span>
                      </div>
                    </div>

                    <!-- 填空题 -->
                    <div class="userAnswer" v-if="s_topics.type == 3">
                      <div class="fillInBlank">
                        <div
                            v-for="(q, index) in fillSymbolStr(t.question)"
                            :key="index"
                        >
                          <span :style="{ fontFamily: testData.selectedFontStyle, fontSize:  testData.fontSize + 'px' }">{{t.correctAnswer[index]}}</span>
                        </div>
                      </div>
                    </div>

                    <!-- 简答题 -->
                    <div class="userAnswer" v-if="s_topics.type == 4">
                      <div class="text">
                        <strong>&nbsp;&nbsp;关键字</strong>
                        <span :style="{ fontFamily: testData.selectedFontStyle, fontSize:  testData.fontSize + 'px' }">{{t.correctAnswer[index]}}</span>
                        <!-- {{t.correctAnswer}} -->
                      </div>
                    </div>

                    <!-- 正确答案 -->
<!--                    <p class="correctAnswer">-->
<!--                      <strong>正确答案: </strong>-->
<!--                      {{ t.correctAnswer }}-->
<!--                    </p>-->
                    <div>
<!--                      <div class="difficulty" :style="{ fontFamily: testData.selectedFontStyle, fontSize:  testData.fontSize + 'px' }">-->
<!--                        <strong>难度: </strong>-->
<!--                        <span>{{ t.difficulty }}</span>-->
<!--                      </div>-->
<!--                      <div class="topicScore" :style="{ fontFamily: testData.selectedFontStyle, fontSize:  testData.fontSize + 'px' }">-->
<!--                        <strong>分值: </strong>-->
<!--                        <span>{{ t.score }}</span>-->
<!--                        (分)-->
<!--                      </div>-->
<!--                      <div class="required">-->
<!--                        <strong>是否必填: </strong>-->
<!--                        <el-switch-->
<!--                            v-model="t.required"-->
<!--                            active-color="#409EFF"-->
<!--                            inactive-color="#ccc"-->
<!--                        >-->
<!--                        </el-switch>-->
<!--                      </div>-->
                    </div>
<!--                    <div class="analysis">-->
<!--                      <strong>题目解析: </strong>-->
<!--                      <span>{{ t.analysis }}</span>-->
<!--                    </div>-->
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div style="clear: both"></div>
        </div>
          </el-col>
          <el-col  :span="2">
            <div style="text-align: center">
              <!--打印试卷-->
              <el-button
                  style="margin: 20px"
                  type="primary"
                  @click="install()"
              >导出pdf
              </el-button>
              <el-button
                  style="margin: 20px"
                  type="primary"
                  @click="exportWord()"
              >导出word
              </el-button>

              <!--打印试卷-->
              <el-button
                  style="margin: 20px"
                  type="primary"
                  @click="switchToA4()"
              >切到A4
              </el-button>
              <el-button
                  style="margin: 20px"
                  type="primary"
                  @click="switchToA3()"
              >切到A3
              </el-button>
            </div>
          </el-col>

        </el-row>
      </div>
    </div>

  </div>

</template>

<script>
import CKEditor from "ckeditor4-vue";
import "@/assets/less/testPaper.less";
import testPaperMixin from "@/mixins/testPaper-mixin.js";
// 导出页面为PDF格式
import html2Canvas from 'html2canvas'
import JsPDF from 'jspdf';
import FileSaver from "file-saver";
import htmlDocx from "html-docx-js/dist/html-docx";
import Vue from "vue";
var userName = JSON.parse(localStorage.getItem("user")).username// 要保证取到
export default {
  name: "TestPaperTch",
  mixins: [testPaperMixin],
  components: {
    // Use the <ckeditor> component in this view.
    // ckeditor: CKEditor.component,
    ckeditor: CKEditor.component,
  },
  data() {
    return {
      aWidth:'794px',
      answer: 1,
      editMode: false,  // 编辑状态标志位
      editedQuestion: '',
      editorData: '',//符文本编辑器的内容
      //编译器的网址
      editorUrl: 'http://localhost:8080/ckeditor/ckeditor.js',
      editorConfig: {
        removeButtons: 'Source,Templates,Save,Print,PasteText,Scayt,Language,ShowBlocks,About',
        // 工具栏组
        toolbarGroups: [
          {name: 'document', groups: ['mode', 'document', 'doctools']},
          {name: 'clipboard', groups: ['clipboard', 'undo']},
          {name: 'editing', groups: ['find', 'selection', 'spellchecker', 'editing']},
          {name: 'forms', groups: ['forms']},
          {name: 'basicstyles', groups: ['basicstyles', 'cleanup']},
          {name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph']},
          {name: 'links', groups: ['links']},
          {name: 'insert', groups: ['insert']},
          {name: 'styles', groups: ['styles']},
          {name: 'colors', groups: ['colors']},
          {name: 'tools', groups: ['tools']},
          {name: 'others', groups: ['others']},
          {name: 'about', groups: ['about']},
          {name: 'math', groups: ['MathType']},
        ],
        removePlugins: 'heading, link, list',
        allowedContent: true,
      },//富文本编辑器的配

      //按题目类型分类好的题目数据
      //题目类型==>  0:单选题  1:多选题  2:判断题  3:填空题  4:简答题
      sortedTopics: [
        {type: 0, topic_content: [], score: 0, showAllScore: false},
        {type: 1, topic_content: [], score: 0, showAllScore: false},
        {type: 2, topic_content: [], score: 0, showAllScore: false},
        {type: 3, topic_content: [], score: 0, showAllScore: false},
        {type: 4, topic_content: [], score: 0, showAllScore: false},
      ],

      // //试卷数据
      testData: {
        selectedFontStyle: 'Arial, sans-serif',
        fontSize: 16,
        paperId: -1,
        type:null,
        paperName: "点击编辑试卷名称", //试卷名称
        time: 60, //答题时间
        topicNum: 0, //题目数量
        passMark: 0,
        creatorName: userName,
        // creatorName: this.$store.state.userName,
      },

      //用户数据
      userData: {
        id: "",
        name: "admin",
      },

      //当前编辑的题目索引
      editInedx: {
        type: "-1",
        index: "-1",
        input: "-2",
      },

      topicDifficultyOptions: ["简单", "中等", "困难"], //题目难度选项

      //侧导航栏是否悬浮
      isFixed: false,
      topic_nav_style: "top:0px",
    };
  },

  computed: {
    params() {
      return this.$route.params;
    },
    //试卷总分
    totalScore() {
      var score = 0;
      this.sortedTopics.forEach((element) => {
        element.topic_content.forEach((item) => {
          // console.log(typeof(item.score));
          score += parseInt(item.score);
        });
      });
      this.testData.totalScore = score;
      return score;
    },

    //按填空符(三个下划线)划分字符串
    fillSymbolStr() {
      return function (str) {
        var q = str.split("___");
        return q;
      };
    },
  },

  created() {
    this.getTestPaper();
    this.answer = this.$route.params.answer
    console.log(this.answer)
  },

  mounted() {
    // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
    window.addEventListener("scroll", this.handleScroll);
  },

  methods: {
    // JavaScript 函数
switchToA3() {
  // 获取所有匹配选择器的元素集合
  this.aWidth='1123px'
},

switchToA4() {
  this.aWidth='794px'
  // 获取所有匹配选择器的元素集合
//   var pageContainers = document.querySelectorAll('.page-container');
//
// // 遍历 NodeList 中的每个元素，为每个元素添加类名
//   pageContainers.forEach(function(container) {
//     container.classList.remove('a3');
//   });
},
//编辑试卷---获取试卷信息
    async getTestPaper() {
      let params = {
        examId: this.$route.params.tp_id,
      };
      await this.request.get("/paper/getTestPaperByTp_id", {params}).then((res) => {
        // this.testData = res.data
        console.log("res.data ==> ", res);

        if (res.code == 200) {
          var resTestData = res.data;
        } else {
          return;
        }

        //处理试卷的题目数据
        resTestData.topicTchDTOList.forEach((item) => {
          if (item.topicType == 4 || item.topicType == 3 || item.topicType == 1) {
            item.correctAnswer = item.correctAnswer.split(/[\n]/g);
          }
          //按换行符分割字符串
          item.choice = item.choice.split(/[\n]/g);
          item.required = item.required === 1 ? true : false;
        });

        this.testData = resTestData;
        console.log("testData ==> ", this.testData);

        //按题目类型分类并保存
        var topics = this.testData.topicTchDTOList;
        for (let i = 0; i < topics.length; i++) {
          for (let item of this.sortedTopics) {
            //     // console.log(topics[i].topicType,item.type);
            if (topics[i].topicType == item.type) {
              item.topic_content.push(topics[i]);
            }
          }
        }
        console.log(this.sortedTopics);
      });
    },
    //滚动事件
    handleScroll() {
      let scrollTop =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop; // 滚动条偏移量
      if (scrollTop > 154) {
        this.topic_nav_style = "top:" + (scrollTop + 20) + "px";
        this.isFixed = true;
      } else {
        this.isFixed = false;
      }
    },

    install () {
      //获取页面标题，作为文件名称，也可以使用时间戳生成不重复的文件名使用
      let title = this.testData.paperName
      html2Canvas(document.querySelector('#testPaper'), {
        allowTaint: true,
        //防止页面过宽导致右侧出现黑灰色背景区域
        scale: 2
      }).then(function (canvas) {
            let contentWidth = canvas.width
            let contentHeight = canvas.height
            //A4纸张标准宽高进行转换
            let pageHeight = contentWidth / 592.28 * 841.89
            let leftHeight = contentHeight
            let position = 0
            let imgWidth = 595.28
            let imgHeight = 592.28 / contentWidth * contentHeight
            let pageData = canvas.toDataURL('image/jpeg', 1.0)
            let PDF = new JsPDF('', 'pt', 'a4')
            if (leftHeight < pageHeight) {
              PDF.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight)
            } else {
              while (leftHeight > 0) {
                PDF.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
                leftHeight -= pageHeight
                position -= 841.89
                if (leftHeight > 0) {
                  PDF.addPage()
                }
              }
            }
            //文件导出生成
            PDF.save(title + '.pdf')
          }
      )
    },

    /**导出data */
    exportWord() {
      const cssStyle = `<style>
       #app {
    overflow: visible;
    //background: #d3ebff;
}

//html {
//    background: #d3ebff;
//}

.w {
    position: relative;
    width: 1300px;
    margin: 0 auto;
    padding: 0 10px;
    box-sizing: border-box;
    padding-bottom: 40px;
}

.testPaper .title,
.topics,
.topic-nav {
    background: #ffffff;
    border-radius: 2px;
    box-shadow: 0 2px 12px 0 rgb(0, 0, 0);
}
.testPaper {
    .test-content {}

    .topics {
        float: right;
        width: 890px;
        box-sizing: border-box;
        padding: 16px;
        margin-bottom: 30px;
    }
}


.testPaper .title {
    // height: 128px;
    padding: 10px 30px 10px 30px;
    margin-bottom: 10px;

    .testName .el-input__inner {
        margin: 0 0 20px 40px;
        border: none;
        background: rgba(255, 255, 255, 0);
        font-size: 20px;
        color: #333;
        font-weight: bold;
        text-decoration: underline;
    }

    ul {
        margin-bottom: 10px;
    }

    ul::after {
        content: '';
        display: block;
        clear: both;
    }

    .test-info {
        float: left;
        margin-right: 20px;

        i {
            color: #333;
            position: relative;
            top: 2px;
            left: 2px;

        }
    }

    .test-info:last-child {
        margin: 0;
    }

    .user-info {
        float: left;
        margin-top: 10px;
        margin-right: 40px;
    }

    .el-input-number {
        width: 72px;
    }

    .el-input-number.is-controls-right .el-input__inner {
        padding-left: 0px;
        padding-right: 28px;
    }
}

.testPaper .title.fixed {
    position: fixed;
    z-index: 999;
    top: 0;
    width: 1180px;
    box-sizing: border-box;
    padding: 14px 30px 0px 30px;
}



.topics .topic {
    .bigQuestionName {
        position: relative;
        display: flex;
        justify-content: space-between;
    }

    .bigQuestionName h4 {
        margin: 20px 0;
    }

    .bigQuestionName .allScore {
        margin: 20px 0;

    }

    .bigQuestionName .allScore button {
        height: 28px;
    }

    .bigQuestionName .allScore button.active {
        background: #666;
        color: #fff;
    }

    .bigQuestionName .allScore .el-input {
        position: absolute;
        top: -30px;
        right: 0;
        width: 120px;

    }

    .bigQuestionName .allScore .el-input::before {
        content: '';
        display: inline-block;
        position: absolute;
        bottom: -4px;
        left: 7px;
        transform: rotate(-45deg);
        width: 8px;
        height: 8px;
        border: 1px solid transparent;
        border-left: 1px solid #DCDFE6;
        border-bottom: 1px solid #DCDFE6;
    }

    .bigQuestionName .allScore .el-input::after {
        content: '';
        display: inline-block;
        position: absolute;
        bottom: -3px;
        left: 8px;
        transform: rotate(-45deg);
        border: 4px solid transparent;
        border-left: 4px solid #fff;
        border-bottom: 4px solid #fff;
    }


    .topic-content {
        position: relative;
        padding: 10px 8px;
        border-radius: 8px;
    }

    .topic-content.isEdit {
        // background: #f5f5f5;
    }

    .topic-content ._location {
        position: absolute;
        top: -50px;
        width: 10px;
        height: 10px;
    }

    .required-symbol {
        color: red;
    }

    .question {
        margin-bottom: 20px;
        font-size: 16px;
        line-height: 26px;
        color: #333;
        display: flex;
    }

    .question .el-textarea {
        width: 730px;
    }


    .el-radio,
    .el-checkbox {
        width: 600px;
        margin-bottom: 2px;
    }

    .userAnswer .el-textarea {
        width: 500px;
    }

    .userAnswer .topicNavIndex {
        display: inline-block;
        width: 30px;
    }

    .score {
        float: right;
    }

    .addRadios {
        margin: 4px 0 0 30px;
    }

    .delRadios.el-button {
        position: relative;
        top: -2px;
        padding: 4px 4px;
        margin-left: 12px;
    }

    .correctAnswer {
        padding-left: 12px;
    }

    .topicScore,
    .difficulty,
    .required {
        display: inline-block;
        box-sizing: border-box;
        width: 33%;
        padding: 0 12px;
        margin-bottom: 10px;
        font-size: 14px;
    }

    .difficulty .el-input {
        width: 120px;
    }

    .analysis,
    .correct_answer {
        padding: 0 10px;
        font-size: 14px;
    }

    .el-radio,
    .el-checkbox {
        display: block;
        margin-left: 20px;
        color: #222;
    }

    .el-radio__label,
    .el-checkbox__label {
        font-size: 16px;
        line-height: 30px;
        word-wrap: break-word;
        white-space: normal;
    }

    .el-checkbox__input {
        float: left;
        margin-top: 8px;
    }

    .fillInBlank .el-textarea {
        width: 50%;
        margin-left: 30px;
        margin-top: 10px;
    }

    .text .el-textarea {
        width: 400px;
        margin: 4px 0 0 30px;
    }

    .text .addRadios {
        margin-bottom: 10px;
    }

    .newTopic {
        margin: 10px 0;
        padding: 10px 0;
        text-align: center;
        color: #64a9e3;
        border: 1px solid #64a9e3;
        cursor: pointer;
    }

}



.testPaper .topic-nav {
    float: left;
    box-sizing: border-box;
    width: 388px;
    min-height: 300px;
    padding: 14px;

    .tool {
        display: flex;
        justify-content: space-between;
        width: 200px;
        height: 30px;
        margin: 0 auto;


        .el-button {
            padding: 6px 10px;
        }
    }

    .topic-nav-describe {
        width: 140px;
        margin: 0 auto;
        font-size: 14px;

        .topic-nav-but {
            display: inline-block;
            position: relative;
            top: 4px;
            width: 16px;
            height: 16px;
            color: #64a9e3;
            border: 1px solid #64a9e3;
            border-radius: 2px;
        }

        .topic-nav-but.hasAnswer {
            background-color: #64a9e3;
            color: #fff;
        }

        .space {
            display: inline-block;

            width: 20px;
        }
    }

    .topic-nav-item {
        margin-bottom: 10px;
    }

    .nav-title {
        width: 50px;
        font-size: 14px;
        margin-left: 10px;
    }

    .topic-nav-button {
        display: inline-block;
        width: 30px;
        height: 30px;
        text-align: center;
        line-height: 30px;
        margin: 10px 6px 0 8px;
        color: black;
        border: 1px solid black;
        border-radius: 2px;
        cursor: pointer;
        transition: 0.5s;
    }
    .topic-nav-button:hover {
        background-color: blue;
    }

    .topic-nav-button.hasAnswer {
        background-color: #64a9e3;
        color: #fff;
    }
}

.topic-nav.isFixed {
    position: absolute;
    top: 154px;
    left: 10px;
    // transform: 0.5s;
}

.testPaper .back-top {
    position: fixed;
    bottom: 50px;
    right: 100px;
    background: #ee9900;
    color: #333;
    font-size: 18px;
    border-radius: 2px;
    padding: 6px 8px;
    cursor: pointer;
}

.el-radio.is-disabled.is-checked .el-radio__input.is-disabled+span.el-radio__label,
.el-checkbox.is-disabled.is-checked .el-checkbox__input.is-disabled+span.el-checkbox__label {
    color: #409EFF;
}

.topic-nav-button.correct,
.topic-nav-but.correct {
    background: #02c39a !important;
    border: 1px solid #02c39a !important;
    color: #fff !important;
}

.topic-nav-button.error,
.topic-nav-but.error {
    background: #ff6b6b;
    border: 1px solid #ff6b6b !important;
    color: #fff !important;

}

.test-content.publishScore .el-radio.is-disabled.is-checked .el-radio__input.is-disabled+span.el-radio__label,
.test-content.publishScore .el-checkbox.is-disabled.is-checked .el-checkbox__input.is-disabled+span.el-checkbox__label {
    color: #ff6b6b;
}

.el-radio.is-disabled.is-checked.correct .el-radio__input.is-disabled+span.el-radio__label,
.el-checkbox.is-disabled.is-checked.correct .el-checkbox__input.is-disabled+span.el-checkbox__label {
    color: #02c39a !important;
}


.topic-content .correct .el-radio__label {
    color: #02c39a !important;
}


.testPaper .forbid_copy {
    -moz-user-select: none;
    /*火狐*/
    -webkit-user-select: none;
    /*webkit浏览器*/
    -ms-user-select: none;
    /*IE10*/
    -khtml-user-select: none;
    /*早期浏览器*/
    user-select: none;
}</style>`;
      let contentHtml = document.getElementById("testPaper").innerHTML;
      let content = `
    	 	<!DOCTYPE html><html>
            	<head>
	                <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
	                <head>${cssStyle}</head>
	            </head>
	            <body>
	                <div>
                  		${contentHtml}
                	</div>
	            </body>
            </html>`;
      let converted = htmlDocx.asBlob(content);
      FileSaver.saveAs(converted, this.testData.paperName+".docx");
    },


  },
};
</script>
<style scoped>
/* CSS 样式 */
.page-container {
  /* A4的默认样式 */
  width: 794px; /* A4的宽度，大约为210mm */
  /*height: 1123px; !* A4的高度，大约为297mm *!*/
}

.a3 {
  width: 1123px; /* A3的宽度，大约为297mm */
  /*height: 1587px; !* A3的高度，大约为420mm *!*/
}
</style>